@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - contact_page
-------------------------------------------------- */
.p-contact {
  &__phone {
    margin-bottom: 25px;
    .-phone__link {
      display: inline-block;
      margin-bottom: 8px;
      @include font__en;
      font-weight: 200;
      line-height: 1;
      color: $color_primary;
      .-meta {
        font-size: 2.8rem;
      }
      .-number {
        font-size: 3.8rem;
      }
    }
    .-phone__text {
      font-size: 1.3rem;
      font-weight: 100;
    }
  }
  &__line {
    text-align: center;
    @include mq(medium) {
      text-align: left;
    }
    &--image {
      max-width: 360px;
      margin-right: auto;
      margin-left: auto;
    }
    &--text__big {
      margin-bottom: 10px;
      font-size: clamp(2.0rem, 2.2vw, 2.4rem);
      line-height: 1.4;
    }
  }
  form {
    margin-bottom: 100px;
  }
  &__form {
    .alert-message {
      margin-top: 30px;
      padding: 10px 20px;
      border: solid 3px $color_primary;
      font-weight: bold;
      color: $color_primary;
    }
    .form__input {
      min-width: 500px;
      padding: .38em 10px;
      border: solid 1px $color_border--table;
      @include mq(medium) {
        min-width: auto;
        width: 100%;
      }
    }
    .form__area {
      min-width: 500px;
      padding: .38em 10px;
      border: solid 1px $color_border--table;
      @include mq(medium) {
        min-width: auto;
        width: 100%;
      }
    }
    .form__check {
      display: none;
      &:checked + label::after {
        content: '';
        position: absolute;
        top: 45%;
        left: 10px;
        width: 8px;
        height: 15px;
        border-right: solid 2px $color_primary;
        border-bottom: solid 2px $color_primary;
        transform: translateY(-50%) rotate(45deg);
        z-index: 10;
      }
    }
    .form__label {
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      @include mq(medium) {
        margin-bottom: 10px;
      }
      &:not(:last-child) {
        margin-right: 30px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 26px;
        height: 26px;
        border: solid 1px $color_border--table;
        border-radius: 5px;
        transform: translateY(-50%);
      }
    }
    .error-message {
      position: relative;
      margin-top: 10px;
      font-size: 80%;
      font-weight: bold;
      color: $color_primary;
      &::before {
        content: '＊';
        margin-right: 3px;
        vertical-align: super;
      }
    }
  }
  &__form--table {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    @include mq(large) {
      display: block;
      width: 100%;
    }
    tbody {
      @include mq(large) {
        display: block;
        width: 100%;
      }
    }
    tr.-table__item {
      border-bottom: solid 1px $color_border--table;
      &:first-child {
        border-top: solid 1px $color_border--table;
      }
      @include mq(large) {
        display: block;
        width: 100%;
      }
    }
    .-required {
      margin-left: 5px;
      font-size: 10px;
      vertical-align: super;
      color: $color_primary;
    }
    th.-table__title {
      padding: 15px 20px;
      background-color: $color_gray;
      font-weight: 300;
      letter-spacing: 0.08em;
      @include mq(large) {
        display: block;
        width: 100%;
      }
    }
    td.-table__detail {
      padding: 15px 20px;
      font-weight: 100;
      @include mq(large) {
        display: block;
        width: 100%;
      }
    }
    .-table__detail--inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include mq(medium) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
  &__privacy {
    margin-bottom: 60px;
    padding: 20px;
    background-color: $color_gray;
    text-align: center;
    .-text {
      letter-spacing: 0.1em;
    }
    a {
      text-decoration: underline;
    }
  }
}
