@use "../foundation" as *;

/* --------------------------------------------------
// layout - footer
-------------------------------------------------- */
.footer {
  @at-root {
    .l-footer {
      margin-top: 60px;
      background-color: $color_gray;
    }
    .l-footer__ground {
      padding-top: 50px;
      padding-bottom: 40px;
      background-color: $color_gray;
      text-align: center;
      @include link__opacity;
      @include mq(medium) {
        padding-top: 40px;
        padding-bottom: 30px;
      }
    }
    .l-footer__lower {
      background-color: $color_secondary;
      .l-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 80px;
        @include mq(medium) {
          flex-direction: column-reverse;
          justify-content: center;
          min-height: 102px;
        }
      }
    }
  }
  &__head {
    margin-bottom: 40px;
    &--title {
      position: relative;
      display: inline-block;
      min-width: 500px;
      min-height: 60px;
      margin-bottom: 30px;
      padding: 15px 20px;
      background-color: $color_secondary;
      border-radius: 100px;
      font-size: 2.0rem;
      font-weight: 500;
      line-height: 1.6;
      color: $color_white;
      @include mq(medium) {
        min-width: 80%;
        border-radius: 15px;
      }
      &::after {
        content: '';
        position: absolute;
        right: 50px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 0;
        border-color: $color_secondary transparent transparent transparent;
      }
    }
    &--text {
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  &__logo {
    max-width: 257px;
    margin-right: auto;
    margin-bottom: 24px;
    margin-left: auto;
  }
  &__contact--phone {
    margin-bottom: 25px;
    .-phone__link {
      display: inline-block;
      margin-bottom: 8px;
      @include font__en;
      font-weight: 200;
      line-height: 1;
      color: $color_primary;
      .-meta {
        font-size: 2.8rem;
      }
      .-number {
        font-size: 3.8rem;
      }
    }
    .-phone__text {
      font-size: 1.3rem;
      font-weight: 100;
    }
  }
  &__contact--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $color_secondary;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 100;
    color: $color_white;
    @include mq(small) {
      font-size: 1.3rem;
    }
    .-btn__icon {
      display: flex;
      align-items: center;
      margin-right: 15px;
      @include mq(small) {
        margin-right: 10px;
      }
    }
    .-icon__mail {
      display: block;
      max-width: 15px;
      margin-right: 3px;
    }
    .-icon__line {
      display: block;
      max-width: 17px;
    }
  }
  &__copyright {
    @include font__en;
    font-weight: 100;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    color: $color_white;
    @include mq(small) {
      font-size: 1.0rem;
    }
  }
}
