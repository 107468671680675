@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - column_page
-------------------------------------------------- */
.p-column {
  .c-article_cassette {
    margin-bottom: 40px;
  }
  .c-article_cassette--switch {
    min-height: 80px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: $color_gray;
    @include mq(medium) {
      margin-bottom: 0;
    }
  }
}
.p-column_detail {
  &--head {
    display: flex;
    align-items: center;
    .-date {
      min-width: 90px;
      margin-right: 10px;
      @include font__en;
      font-size: clamp(1.2rem, 1vw, 1.3rem);
      font-weight: 100;
      line-height: 1;
      letter-spacing: 0.1em;
    }
  }
}
