@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - pagination
-------------------------------------------------- */
.c-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  :where(.c-pagination__prev,.c-pagination__next) {
    a {
      position: relative;
      display: block;
      width: 35px;
      height: 35px;
      background-color: transparent;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: clamp(10px, 1.5vw, 19px);
        height: clamp(10px, 1.5vw, 19px);
        border-top: solid 1px $color_secondary;
        border-right: solid 1px $color_secondary;
      }
      &:where(:hover,:focus) {
        background-color: rgba($color: $color_link, $alpha: .05);
      }
    }
  }
  &__prev {
    a::before {
      left: 65%;
      transform: translateY(-50%) translateX(-50%) rotate(-135deg);
    }
  }
  &__next {
    a::before {
      left: 35%;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
  span:not(.-prev,.-next) {
    background-color: #000;
  }
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .-list__item {
      margin-right: 3px;
      margin-left: 3px;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background-color: transparent;
      @include font__en;
      font-size: 1.6rem;
      font-weight: 200;
      color: $color_secondary;
      &:where(:hover,:focus) {
        background-color: rgba($color: $color_link, $alpha: .05);
      }
    }
  }
  .-current {
    a {
      background-color: $color_secondary;
      color: $color_white;
      pointer-events: none;
    }
  }
}
