/* --------------------------------------------------
// _var - 変数宣言
-------------------------------------------------- */

/* color */
/* ---------------------------------------- */
$color_primary: #ec6c00;
$color_secondary: #032739;
$color_secondary--light: #626b70;
$color_tertiary: #c7a956;
$color_white: #fff;
$color_gray: #f7f7f8;
$color_gray--light: #cdd4d7;
$color_black: #032739;
$color_red: #db3908;
$color_link: #3e90b2;
$color_border: #dcdedf;
$color_border--table: #d0d2d4;
$color_category--1: #2285b6;
$color_category--2: #59be92;
$color_category--3: #d252b5;
$color_category--4: #e87442;
$color_category--5: #6a55c7;

/* ブレイクポイント */
/* ---------------------------------------- */
$width_break--large: 68.75em;
$width_break--medium: 48em;
$width_break--small: 35em;

/* デザイン幅 */
/* ----------------------------------------*/
$width_design--pc: 1100px;
$width_design--sp: 750px;

/* font */
/* ---------------------------------------- */
$font-hiragino: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font-noto: 'Noto Sans JP', sans-serif;
$font-en: 'Inter', sans-serif;
