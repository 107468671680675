@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - top_page
-------------------------------------------------- */
.p-top {
  margin-bottom: -60px;
  .p-top__section {
    padding-top: 70px;
    padding-bottom: 70px;
    &--title {
      margin-bottom: 40px;
      text-align: center;
      .-en {
        display: block;
        font-size: clamp(3.5rem, 4vw, 5.0rem);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: $color_secondary;
      }
      .-ja {
        display: block;
        margin-top: 15px;
        font-size: 1.4rem;
        font-size: clamp(1.2rem, 1.5vw, 1.4rem);
        font-weight: 100;
        line-height: 1;
        color: $color_secondary--light;
      }
    }
  }
  &__fv {
    position: relative;
    width: 100%;
    height: 480px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include mq(medium) {
      height: 250px;
    }
    .p-top__fv--bg {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color_white;
    }
    .-bg__img {
      overflow-x: hidden;
      height: 480px;
      @include mq(medium) {
        height: 250px;
      }
      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 3/1;
      }
    }
    .l-wrap {
      position: relative;
      height: 100%;
      z-index: 10;
    }
    &--text {
      position: absolute;
      padding: .4em 20px .55em;
      background-color: $color_white;
      @include font__ja--noto;
      font-size: 2.8rem;
      line-height: 1.2;
      letter-spacing: 0.1em;
      color: $color_secondary;
      @include mq(medium) {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 1.4rem;
      }
      &.-first {
        bottom: 22%;
        right: 30%;
        @include mq(small) {
          right: 38%;
        }
        @include mq(large_up) {
          bottom: 25%;
          right: 25%;
        }
      }
      &.-second {
        bottom: 7%;
        right: 15px;
        @include mq(large_up) {
          bottom: 10%;
        }
      }
    }
  }
  &__works {
    > .l-wrap {
      @include mq(medium) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .works__slider {
      padding-top: 30px;
      @include mq(medium_up) {
        margin-bottom: 20px;
      }
      &--list {
        position: relative;
      }
      &--list__thmbnail {
        width: min(100%, 1000px);
        height: auto;
        max-height: 460px;
        margin-right: auto;
        margin-left: auto;
        aspect-ratio: 2 / 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
      &--list__detail {
        width: min(100%, 600px);
        padding: 20px;
        text-align: center;
        @include mq(medium_up) {
          position: absolute;
          bottom: 20px;
          left: 50%;
          min-width: 600px;
          background-color: rgba($color: $color_secondary, $alpha: .7);
          transform: translateX(-50%);
        }
        @include mq(medium) {
          position: static;
        }
        .c-category {
          min-width: 150px;
          margin-bottom: 10px;
          @include mq(medium_up) {
            position: absolute;
            top: -15px;
            left: -15px;
          }
        }
        .-detail__title {
          font-size: 2.4rem;
          font-size: clamp(2.1rem, 3vw, 2.4rem);
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: 0.05em;
          color: $color_white;
          @include mq(medium) {
            font-weight: 500;
            color: $color_secondary;
          }
        }
        .-detail__nameplate {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
          color: $color_white;
          @include mq(medium) {
            margin-top: 10px;
            color: $color_secondary;
          }
        }
        .-name {
          font-size: 1.3rem;
          font-size: clamp(1.2rem, 1.5vw, 1.3rem);
          font-weight: 100;
          &::after {
            content: '/';
            padding-left: 10px;
            color: $color_white;
            @include mq(medium) {
              color: $color_secondary;
            }
          }
        }
        .-date {
          padding-left: 10px;
          @include font__en;
          font-size: clamp(1.2rem, 1.5vw, 1.3rem);
          font-weight: 100;
          letter-spacing: 0.1em;
        }
      }
      .slick-slider {
        @include mq(medium) {
          margin-bottom: 0;
        }
      }
      .slick-arrow {
        top: 50%;
        width: clamp(22px, 4vw, 44px);
        height: clamp(22px, 4vw, 44px);
        background-color: rgba($color: $color_secondary--light, $alpha: .8);
        border-radius: 100px;
        z-index: 100;
        @include mq(medium) {
          top: 25%;
        }
        &::before {
          font-size: 0;
          content: '';
          position: absolute;
          top: 50%;
          width: clamp(7px, 1vw, 14px);
          height: clamp(7px, 1vw, 14px);
          border-top: solid 1px $color_white;
          border-right: solid 1px $color_white;
        }
      }
      .slick-prev {
        left: 10px;
        @include mq(large_up) {
          left: 0;
        }
        &::before {
          left: 57%;
          transform: translateY(-50%) translateX(-50%) rotate(-135deg);
        }
      }
      .slick-next {
        right: 10px;
        @include mq(large_up) {
          right: 0;
        }
        &::before {
          left: 45%;
          transform: translateY(-50%) translateX(-50%) rotate(45deg);
        }
      }
      .slick-dots {
        top: -45px;
        bottom: unset;
        li {
          width: 10px;
          height: 10px;
        }
        li button {
          width: 10px;
          height: 10px;
          padding: 0;
        }
        li button::before {
          width: 10px;
          height: 10px;
          background-color: $color_secondary--light;
          border-radius: 10px;
          font-size: 0;
          line-height: 10px;
        }
        li.slick-active button::before {
          background-color: $color_secondary;
        }
      }
    }
  }
  &__service {
    background-color: $color_secondary;
    .p-top__section--title :is(.-en,.-ja) {
      color: $color_white;
    }
    .service__list {
      display: grid;
      margin-bottom: 60px;
      @include mq(medium_up) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 40px;
      }
      @include mq(medium) {
        grid-template-columns: 1fr;
        row-gap: 20px;
      }
      .-list__item {
        overflow: hidden;
        position: relative;
        background-color: $color_secondary;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        aspect-ratio: 2 / 1;
        text-align: center;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .7);
          pointer-events: none;
          @include anime03;
          opacity: 1;
          z-index: 1;
        }
        &:where(:hover,:focus) {
          &::before {
            opacity: .3;
          }
        }
        a {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        .-en {
          display: block;
          @include font__en;
          font-size: clamp(2.5rem, 2vw, 3.0rem);
          font-weight: 100;
          line-height: 1;
          letter-spacing: 0.15em;
          color: $color_tertiary;
        }
        .-ja {
          display: block;
          margin-top: 15px;
          font-size: clamp(1.3rem, 2vw, 1.6rem);
          line-height: 1;
          letter-spacing: 0.15em;
          color: $color_white;
        }
      }
      &--house {
        background-image: url(../img/top/service_img01.jpg);
      }
      &--renovation {
        background-image: url(../img/top/service_img02.jpg);
      }
      &--furniture {
        background-image: url(../img/top/service_img03.jpg);
      }
      &--shop {
        background-image: url(../img/top/service_img04.jpg);
      }
      &--reform {
        background-image: url(../img/top/service_img05.jpg);
      }
      &--other {
        &::before {
          background-color: rgba(0, 0, 0, .3) !important;
        }
      }
    }
    .c-btn {
      &:is(:hover,:focus) {
        border-color: $color_secondary--light;
      }
    }
  }
  &__column {
    background-color: $color_gray;
  }
  &__recruit {
    position: relative;
    background: url(../img/top/recruit_img01.jpg) no-repeat 70% center;
    background-size: cover;
    @include mq(large_up) {
      min-height: 520px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(198, 233, 250, .6);
    }
    .recruit__text {
      max-width: 700px;
      margin-right: auto;
      margin-left: auto;
      font-size: clamp(1.4rem, 2vw, 1.6rem);
      font-weight: 500;
      letter-spacing: 0.15em;
      color: $color_secondary;
    }
  }
}
