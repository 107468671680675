@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - about_page
-------------------------------------------------- */
.p-about {
  .c-page_main {
    p strong,
    p b {
      position: relative;
      padding-bottom: 4px;
      border-bottom: solid 2px $color_secondary;
      font-weight: 500;
      color: $color_secondary;
    }
  }
}
