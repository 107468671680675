@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - category
-------------------------------------------------- */
.c-category {
	display: inline-block;
	min-width: 120px;
	min-height: 26px;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 2.6rem;
	text-align: center;
	letter-spacing: 0.08em;
	color: $color_white;
	&--01 {
		background-color: $color_category--1;
	}
	&--02 {
		background-color: $color_category--2;
	}
	&--03 {
		background-color: $color_category--3;
	}
	&--04 {
		background-color: $color_category--4;
	}
	&--05 {
		background-color: $color_category--5;
	}
}
