@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - article_cassette
-------------------------------------------------- */
@keyframes c-article_cassette__tab_on {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
%tab_effect {
  animation-name: c-article_cassette__tab_on;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
%new_icon {
  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    width: 16px;
    height: 16px;
    background-color: $color_red;
    border-radius: 16px;
    z-index: 15;
    @include mq(medium) {
      top: 0;
      left: 0;
    }
  }
}
.c-article_cassette {
  width: 100%;
  .-tab__switch {
    display: none;
  }
  &--switch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .is-active a {
      background-color: $color_secondary;
      color: $color_white;
    }
    .-switch__list {
      @include mq(medium) {
        min-width: calc((100% - 60px) / 3);
      }
    }
  }
  .-tab__list {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
    padding: 6px 10px 8px;
    background-color: transparent;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    color: #222;
    cursor: pointer;
  }
  .-tab__content {
    display: none;
  }
  @at-root {
    .p-column {
      .-tab__content {
        display: block;
      }
    }
  }
  /* カテゴリ数 5 → 00から05まで */
  $tab_number: "";
  @for $i from 0 through 5 {
    @if $i < 10 {
      $tab_number: "0#{$i}";
    } @else {
      $tab_number: "#{$i}";
    }
    #tab__switch--#{$tab_number}:checked ~ .c-article_cassette--switch #tab__list--#{$tab_number} {
      background-color: $color_secondary;
      color: $color_white;
    }
    #tab__switch--#{$tab_number}:checked ~ .c-article_cassette--card #tab__content--#{$tab_number} {
      display: grid;
      @extend %tab_effect;
    }
    #tab__switch--#{$tab_number}:checked ~ .c-article_cassette--wide #tab__content--#{$tab_number} {
      display: block;
      @extend %tab_effect;
    }
  }
  .-tab__content {
    row-gap: 20px;
    @include mq(medium_up) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
    }
    @include mq(medium) {
      grid-template-columns: 1fr;
    }
  }
  .article_cassette--card {
    position: relative;
    padding: 10px;
    background-color: $color_white;
    @include mq(medium) {
      padding: 5px;
    }
    &.-new {
      @extend %new_icon;
    }
    a,
    > div {
      position: relative;
      display: block;
      height: 100%;
      color: $color_secondary;
      @include mq(medium) {
        display: flex;
      }
    }
    a {
      &:is(:hover,:focus) {
        .-title {
          text-decoration: underline;
          color: $color_link;
        }
        .article_cassette--card__thumbnail img {
          transform: scale(1.1);
        }
      }
    }
    .c-category {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
    &__thumbnail {
      overflow: hidden;
      position: relative;
      display: block;
      width: 100%;
      height: 200px;
      z-index: 1;
      @include mq(medium) {
        width: 140px;
        height: 100px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include anime03;
      }
    }
    &__detail {
      padding: 10px 20px;
      @include mq(medium) {
        width: calc(100% - 140px);
        padding: 0 10px;
      }
      .-date {
        margin-bottom: 15px;
        @include font__en;
        font-size: clamp(1.0rem, 1vw, 1.3rem);
        font-weight: 100;
        line-height: 1;
        letter-spacing: 0.1em;
      }
      .-title {
        overflow: hidden;
        display: -webkit-box;
        width: 100%;
        font-size: clamp(1.4rem, 2vw, 1.6rem);
        font-weight: 500;
        letter-spacing: 0.1em;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        @include mq(medium) {
          line-height: 1.4;
        }
      }
    }
  }
  .article_cassette--wide {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $color_border;
    @include mq(medium) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.-new {
      @extend %new_icon;
      &::before {
        top: 25px;
        left: 5px;
        @include mq(medium) {
          top: 10px;
          left: 0;
        }
      }
    }
    a,
    > div {
      position: relative;
      display: block;
      height: 100%;
      padding: 20px;
      background-color: $color_white;
      color: $color_secondary;
    }
    a {
      &:where(:hover,:focus) {
        background-color: rgba($color: $color_link, $alpha: .05);
      }
    }
    > div {
      .article_cassette--wide__detail {
        padding-bottom: 0;
        &::after {
          content: '';
          font-size: 0;
        }
      }
    }
    &__head {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      @include mq(medium) {
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .-date {
        min-width: 90px;
        margin-top: 7px;
        @include font__en;
        font-size: clamp(1.2rem, 1vw, 1.3rem);
        font-weight: 100;
        line-height: 1;
        letter-spacing: 0.1em;
        @include mq(medium_up) {
          order: 1;
          margin-right: 10px;
          text-align: left;
        }
        @include mq(medium) {
          order: 3;
          text-align: right;
        }
      }
      .c-category {
        order: 2;
        position: relative;
        min-width: 120px;
        z-index: 5;
      }
      .-title {
        display: block;
        width: 100%;
        font-size: clamp(1.8rem, 2.5vw, 2.4rem);
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.05em;
        @include mq(medium_up) {
          order: 3;
          width: calc( 100% - (100px + 120px) );
          margin-top: -0.3em;
          margin-left: 20px;
        }
        @include mq(medium) {
          order: 1;
          margin-bottom: 15px;
          line-height: 1.4;
        }
      }
    }
    &__detail {
      position: relative;
      width: 100%;
      padding-bottom: 2.5em;
      &::after {
        content: '全て読む';
        position: absolute;
        right: 0;
        bottom: 0;
        @include font__ja;
        text-decoration: underline;
      }
      .-text {
        overflow: hidden;
        display: -webkit-box;
        width: 100%;
        font-size: clamp(1.3rem, 1.5vw, 1.5rem);
        font-weight: 400;
        line-height: 1.86;
        letter-spacing: 0.08em;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        @include mq(medium) {
          line-height: 1.4;
        }
      }
    }
  }
}
