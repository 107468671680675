@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - page_heading
-------------------------------------------------- */
.c-page_heading {
  position: relative;
  width: 100%;
  min-height: 190px;
  margin-bottom: 30px;
  @include mq(medium) {
    min-height: 150px;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    min-height: 190px;
    object-fit: cover;
    font-size: 0;
    transform: translateY(-50%) translateX(-50%);
    @include mq(medium) {
      min-height: 150px;
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 190px;
    padding-top: 20px;
    padding-bottom: 20px;
    @include mq(medium) {
      min-height: 150px;
    }
  }
  .-en {
    margin-bottom: 5px;
    font-size: clamp(1.2rem, 1.5vw, 1.3rem);
    font-weight: 100;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: $color_tertiary;
  }
  .-ja {
    font-size: clamp(2.5rem, 5vw, 4.6rem);
    font-weight: 100;
    line-height: 1.3;
    letter-spacing: 0.1em;
    color: $color_secondary;
  }
}
