@use "mixin" as *;
@use "var" as *;

/* --------------------------------------------------
// _base - 全体で共通のfont-sizeやcolorなどの共通化
-------------------------------------------------- */
:root {
	--body_padding_top-l: 100px;
	--body_padding_top-s: 55px;
}
*,
*:before,
*:after {
  word-break: break-word;
}
html {
  width: 100%;
  background-color: $color_white;
  @include font__ja;
  font-size: 62.5%;
	font-feature-settings: "palt" 1;
}
body {
  position: relative;
	overflow-x: hidden;
  width: 100%;
	padding-top: var(--body_padding_top-s);
  font-size: 1.5rem;
	font-feature-settings: "palt" 1;
  line-height: 1.8;
  vertical-align: baseline;
  letter-spacing: 0.03em;
  color: $color_black;
	@include anime03;
	@include mq(large_up) {
		padding-top: var(--body_padding_top-l);
	}
	&.is-scroll {
		padding-top: var(--body_padding_top-s);
	}
}
ol, ul, li {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}
q, blockquote {
  	quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
	content: "";
	content: none;
}
a {
	text-decoration: none;
  color: $color_black;
	&:where(:hover,:focus) {
		color: $color_link;
	}
	@include mq(large) {
		&:is(:hover,:focus) {
			@include anime03;
		}
	}
	img {
		border: none;
	}
}
img {
	display: block;
	max-width: 100%;
	width: auto;
	height: auto;
	margin: 0 auto;
}
svg {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}
svg {
	fill: currentColor;
}
input,
button,
select,
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0;
	border: none;
	border-radius: 0;
	outline: none;
	background: none;
  @include font__ja;
}
button {
	color: $color_black;
	cursor: pointer;
}
sup {
	position: relative;
	top: 0.1em;
	font-size: 0.6em;
	vertical-align: super;
}

.pc-only,
.-break_upper {
	display: block;
	@include mq(medium) {
		display: none;
	}
}
.sp-only,
.-break_lower {
	display: none;
	@include mq(medium) {
		display: block;
	}
}
.-bold {
  font-weight: bold;
}
.-center {
  text-align: center;
}
.-color_primary {
  color: $color_primary;
}
.-color_secondary {
  color: $color_secondary;
}

.-icon {
	&__facebook img {
		width: 100%;
		max-width: 11px;
	}
	&__mail img {
		width: 100%;
		max-width: 20px;
	}
	&__instagram img {
		width: 100%;
		max-width: 20px;
	}
	&__line img {
		width: 100%;
		max-width: 26px;
	}
}

// displayAnimation
///////////////////////////////////////
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
.-fade_in {
  opacity: 0;
  transform: translateY(10%);
  transition: 2s;
  &.is-show {
    opacity: 1;
		transform: translateY(0);
  }
}
.-fade_in--left {
  opacity: 0;
  transform: translateX(10%);
  transition: 2s;
  &.is-show {
    opacity: 1;
		transform: translateX(0);
  }
}
