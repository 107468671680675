@use "../foundation" as *;

/* --------------------------------------------------
// layout - main
-------------------------------------------------- */
.l-wrap {
  position: relative;
  width: 100%;
  max-width: calc($width_design--pc + 40px);
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.l-wrap__inner {
  width: 100%;
  max-width: calc($width_design--pc - 100px);
  margin-right: auto;
  margin-left: auto;
}
