@use "var" as *;

/* media query */
/* ---------------------------------------- */
@mixin mq($media_query) {
  @if $media_query == large_up {
    @media screen and (min-width: ($width_break--large + 0.01em)) {
      @content;
    }
  }
  @if $media_query == large {
    @media screen and (max-width: $width_break--large) {
      @content;
    }
  }
  @if $media_query == medium_up {
    @media screen and (min-width: ($width_break--medium + 0.01em)) {
      @content;
    }
  }
  @if $media_query == medium {
    @media screen and (max-width: $width_break--medium) {
      @content;
    }
  }
  @if $media_query == small {
    @media screen and (max-width: $width_break--small) {
      @content;
    }
  }
}

/* font */
/* ---------------------------------------- */
@mixin font__ja {
	font-family: $font-hiragino;
}
@mixin font__ja--noto {
	font-family: $font-noto;
}
@mixin font__en {
	font-family: $font-en;
}

/* animetion */
/* ---------------------------------------- */
@mixin anime03 {
	transition: all 0.3s 0s ease;
}
@mixin anime05 {
	transition: all 0.5s 0s ease;
}

/* link - hover */
/* ---------------------------------------- */
@mixin link__opacity {
  a {
    @include anime03;
  }
  a:link:hover,
  a:focus,
  a[href]:hover {
    opacity: 0.6;
  }
}

/* link bottom border */
/* ---------------------------------------- */
@mixin link__bottom_border($pbottom: -3px, $bcolor: #fff) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: $pbottom;
    left: 0;
    width: 0;
    height: 1px;
    background-color: $bcolor;
    @include anime03;
  }
  &:hover::after {
    width: 100%;
  }
}

/* IE11用 */
/* ---------------------------------------- */
@mixin hack_ie11 {
  @at-root {
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, & {
        @content;
      }
    }
  }
}
