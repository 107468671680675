@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - faq_page
-------------------------------------------------- */
.p-faq {
  .p-faq {
    &__item {
      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
    &__item--question {
      margin-left: clamp(45px, 5vw, 65px);
      padding-left: 0;
      &::before {
        content: 'Q.';
        top: -2rem;
        left: -60px;
        width: auto;
        height: auto;
        background-color: transparent;
        @include font__en;
        font-size: clamp(4rem, 5vw, 5.0rem);
        font-weight: 200;
        color: $color_secondary;
        @include mq(medium) {
          left: -45px;
        }
      }
    }
    &__item--answer {
      position: relative;
      margin-left: clamp(70px, 8vw, 95px);
      &::before {
        content: 'A.';
        position: absolute;
        top: -0.35rem;
        left: -30px;
        margin-right: 10px;
        background-color: transparent;
        @include font__en;
        font-size: clamp(1.6rem, 3vw, 2.0rem);
        font-weight: 700;
        color: $color_primary;
      }
    }
  }
}
