@use "../foundation" as *;

/* --------------------------------------------------
// layout - header
-------------------------------------------------- */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--body_padding_top-s);
  padding-left: 20px;
  padding-right: 20px;
  background-color: $color_gray;
  @include anime03;
  @include mq(large_up) {
    min-height: var(--body_padding_top-l);
  }
  &.is-scroll {
    min-height: var(--body_padding_top-s);
  }
  @at-root {
    .l-header {
      position: fixed;
      top: 0;
      left: 0;
      min-height: var(--body_padding_top-s);
      width: 100%;
      padding: 0;
      background-color: $color_gray;
      z-index: 9000;
    }
    &.l-wrap {
      @include mq(large_up) {
        padding-right: 0;
      }
    }
  }
  &__menu {
    padding: 0;
    @include mq(large) {
      overflow-y: scroll;
      height: 100vh;
      padding: 60px 35px;
    }
  }
  &__logo {
    width: 100%;
    max-width: clamp(162px, 17vw, 250px);
    @include link__opacity;
    @include mq(large) {
      max-width: 162px;
    }
    a {
      display: block;
      @include mq(large_up) {
        height: 26px;
      }
    }
  }
  .gnav__list {
    display: flex;
    @include mq(large) {
      flex-direction: column;
      border-top: solid 1px rgba($color: $color_white, $alpha: .3);
    }
    > .-list__item {
      position: relative;
      @include mq(large_up) {
        &:not(:last-of-type) {
          margin-right: 5px;
        }
        &.is-active {
          .gnav__list--link::before {
            height: 7px;
          }
          .gnav__list--children {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
      @include mq(large) {
        border-bottom: solid 1px rgba($color: $color_white, $alpha: .3);
      }
    }
    &--link {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;
      font-weight: 500;
      @include mq(large_up) {
        height: 47px;
        font-size: 1.4rem;
        color: $color_secondary;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background-color: $color_secondary;
          @include anime03;
        }
      }
      @include mq(large) {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        font-size: 1.6rem;
        font-weight: 600;
        color: $color_white;
      }
      > .-title {
        display: block;
      }
    }
    &--children {
      opacity: 0;
      @include mq(large_up) {
        opacity: 0;
        position: absolute;
        top: 47px;
        left: 0;
        width: 320px;
        padding: 25px 25px 25px 20px;
        background-color: $color_secondary;
        pointer-events: none;
        @include anime03;
      }
      @include mq(large) {
        opacity: 1;
        display: none;
        margin-bottom: 15px;
      }
      .-list__item {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: .75em;
          left: 10px;
          width: 5px;
          height: 1px;
          background-color: $color_white;
        }
        &:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }
    }
    &--sub_link {
      display: block;
      padding-left: 30px;
      font-size: 1.3rem;
      font-weight: 100;
      color: $color_white;
      @include mq(large_up) {
        @include anime03;
        &:hover,
        &:focus {
          background-color: rgba($color: $color_white, $alpha: .2);
        }
      }
      @include mq(large) {
        font-size: 1.5rem;
      }
    }
  }
  &__links {
    display: none;
    @include mq(large) {
      display: block;
      margin-top: 20px;
    }
    .c-sns_links {
      justify-content: center;
    }
  }
  .js-header__menu {
    background-color: transparent;
    @include mq(large) {
      position: absolute;
      top: 0;
      left: 200%;
      width: 100%;
      background-color: rgba($color: $color_secondary, $alpha: .95);
      transition: left .8s ease;
      &.is-open {
        left: 0;
      }
    }
  }
}
.js-scroll_fix {
  &.is-fixed {
    @include mq(large) {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
.js-header__menu_icon {
  position: absolute;
  top: 17px;
  right: 20px;
  display: none;
  width: 23px;
  height: 23px;
  background-color: transparent;
  z-index: 999999;
  @include mq(large) {
    display: block;
    cursor: pointer;
  }
  &__wrap {
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }
  span {
    display: block;
    width: 30px;
    height: 1px;
    background-color: $color_secondary;
    cursor: pointer;
    @include anime03;
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
  &.is-active {
    .-first {
      margin-top: 10px;
      background-color: $color_white;
      transform: rotate(45deg);
    }
    .-second {
      position: relative;
      margin-top: -9px;
      background-color: $color_white;
      transform: rotate(135deg);
    }
    .-third {
      display: none;
    }
  }
}
.js-sp_menu--accordion {
  position: relative;
  @include mq(large) {
    &::after {
      content: '';
      position: absolute;
      top: 45%;
      right: 5px;
      width: 11px;
      height: 11px;
      border-top: solid 1px $color_white;
      border-right: solid 1px $color_white;
      transform: translateY(-50%) rotate(135deg);
      @include anime03;
    }
    &.is-open {
      &::after {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: 100%;
    z-index: 9000;
    cursor: pointer;
    @include mq(large_up) {
      display: none;
    }
  }
}
