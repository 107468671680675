@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - sns links
-------------------------------------------------- */
.c-sns_links {
  display: flex;
  align-items: center;
  @include mq(medium) {
    margin-bottom: 20px;
  }
  .-list__item:not(:last-of-type) {
    margin-right: 40px;
  }
}
