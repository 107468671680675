@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - tag
-------------------------------------------------- */
.c-tag {
  &__nav {
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px 40px;
    border-top: solid 1px rgba($color: $color_black, $alpha: .2);
    border-bottom: solid 1px rgba($color: $color_black, $alpha: .2);
    @include mq(medium) {
      padding: 30px 5px 20px;
    }
    &::before {
      content: 'TAG';
      position: absolute;
      top: 5px;
      left: 5px;
      padding-right: 16px;
      background: url(../img/icon_tag.svg) no-repeat right center;
      background-size: 14px 14px;
      @include font__en;
      font-size: 1.8rem;
      font-weight: 900;
      line-height: 1;
      letter-spacing: 0.15em;
      color: $color_gray--light;
    }
    &--item {
      display: flex;
      align-items: flex-start;
      @include mq(medium) {
        display: block;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .-tag__item--title {
        position: relative;
        display: inline-block;
        width: 170px;
        margin-right: 10px;
        padding-right: 10px;
        @include font__en;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: right;
        text-transform: capitalize;
        color: $color_secondary;
        @include mq(medium) {
          width: auto;
          text-align: left;
        }
        &::after {
          content: '';
          position: absolute;
          top: 54%;
          right: 0;
          width: 5px;
          height: 1px;
          background-color: $color_secondary;
        }
      }
      .-tag__item--list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: calc( 100% - 180px );
        @include mq(medium) {
          width: 100%;
        }
        .-list__item {
          margin-bottom: 5px;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.is-active {
            a {
              background-color: $color_secondary;
              color: $color_white;
            }
          }
        }
        a {
          display: inline-block;
          padding: 0px 12px;
          background-color: $color_gray;
          border-radius: 20px;
          font-size: 1.3rem;
          color: $color_black;
          &:hover {
            background-color: $color_secondary;
            color: $color_white;
          }
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-left: 20px;
    background: url(../img/icon_tag.svg) no-repeat left center;
    background-size: 14px 14px;
    .-list__item {
      margin-bottom: 5px;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &.is-active {
        a {
          background-color: $color_secondary;
          color: $color_white;
        }
      }
    }
    a {
      display: inline-block;
      padding: 0px 12px;
      background-color: $color_gray;
      border-radius: 20px;
      font-size: 1.3rem;
      color: $color_black;
      &:hover {
        background-color: $color_secondary;
        color: $color_white;
      }
    }
  }
}
