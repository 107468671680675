@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - other
-------------------------------------------------- */
.p-other {
  &__title {
    position: relative;
    margin-top: 60px;
    margin-bottom: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: solid 1px $color_secondary;
    font-size: clamp(2.5rem, 3vw, 3.6rem);
    font-weight: 600;
    line-height: 1.4;
    color: $color_secondary;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 5px;
      height: 10px;
      background-color: $color_secondary;
    }
  }
  &__text {
    font-size: clamp(1.4rem, 1.5vw, 1.5rem);
    line-height: 1.8;
    letter-spacing: 0.08em;
    color: $color_secondary--light;
    &:not(:first-child) {
      margin-top: var(--bgb-block_mt);
    }
  }
}
