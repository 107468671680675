@use "../../foundation" as *;

/* --------------------------------------------------
// object/project - case_study page
-------------------------------------------------- */
.p-case_study {
  .c-article_cassette--switch {
    min-height: 80px;
    padding: 20px;
    background-color: $color_gray;
  }
  .-tab__content {
    display: grid;
  }
  .article_cassette--card {
    background-color: $color_gray;
  }
}
