@use "../../foundation" as *;

/* --------------------------------------------------
// object/component - button
-------------------------------------------------- */
.c-btn {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 60px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 20px;
  background-color: $color_white;
  border: solid 3px $color_secondary--light;
  font-size: 1.5rem;
  font-size: clamp(1.3rem, 3vw, 1.5rem);
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  color: $color_secondary--light;
  @include anime03;
  @include mq(medium) {
    width: 70%;
    min-height: 45px;
    border-width: 1px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 200%;
    width: 100%;
    height: 100%;
    background-color: $color_secondary;
    @include anime03;
    z-index: 1;
  }
  &:hover,
  &:focus {
    border-color: $color_secondary;
    color: $color_white;
    cursor: pointer;
    &::before {
      right: 0;
    }
  }
  .form__btn {
    position: relative;
    z-index: 10;
  }
  .-title {
    position: relative;
    z-index: 10;
  }
  &--back {
    margin-top: 60px;
    .-title {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 15;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 25px;
        height: 1px;
        background-color: var(--bgb-color_secondary--light);
        transform: translateY(-50%);
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 2px;
        width: 19px;
        height: 19px;
        border-bottom: solid 1px var(--bgb-color_secondary--light);
        border-left: solid 1px var(--bgb-color_secondary--light);
        transform: translateY(-50%) rotate(45deg);
      }
    }
    &:where(:hover,:focus) {
      .-title {
        &::before {
          background-color: var(--bgb-color_white);
        }
        &::after {
          border-color: var(--bgb-color_white);
        }
      }
    }
  }
}
