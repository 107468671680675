@charset "UTF-8";
/*** The new CSS reset - version 1.8.2 (last updated 23.12.2022) ***/
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

pre {
  all: revert;
}

::placeholder {
  color: unset;
} /*::marker{content:""}*/
:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable=true]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* --------------------------------------------------
// _var - 変数宣言
-------------------------------------------------- */
/* color */
/* ---------------------------------------- */
/* ブレイクポイント */
/* ---------------------------------------- */
/* デザイン幅 */
/* ----------------------------------------*/
/* font */
/* ---------------------------------------- */
/* media query */
/* ---------------------------------------- */
/* font */
/* ---------------------------------------- */
/* animetion */
/* ---------------------------------------- */
/* link - hover */
/* ---------------------------------------- */
/* link bottom border */
/* ---------------------------------------- */
/* IE11用 */
/* ---------------------------------------- */
/* --------------------------------------------------
// _base - 全体で共通のfont-sizeやcolorなどの共通化
-------------------------------------------------- */
:root {
  --body_padding_top-l: 100px;
  --body_padding_top-s: 55px;
}

*,
*:before,
*:after {
  word-break: break-word;
}

html {
  width: 100%;
  background-color: #fff;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 62.5%;
  font-feature-settings: "palt" 1;
}

body {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  padding-top: var(--body_padding_top-s);
  font-size: 1.5rem;
  font-feature-settings: "palt" 1;
  line-height: 1.8;
  vertical-align: baseline;
  letter-spacing: 0.03em;
  color: #032739;
  transition: all 0.3s 0s ease;
}
@media screen and (min-width: 68.76em) {
  body {
    padding-top: var(--body_padding_top-l);
  }
}
body.is-scroll {
  padding-top: var(--body_padding_top-s);
}

ol, ul, li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a {
  text-decoration: none;
  color: #032739;
}
a:where(:hover, :focus) {
  color: #3e90b2;
}
@media screen and (max-width: 68.75em) {
  a:is(:hover, :focus) {
    transition: all 0.3s 0s ease;
  }
}
a img {
  border: none;
}

img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

svg {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

svg {
  fill: currentColor;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

button {
  color: #032739;
  cursor: pointer;
}

sup {
  position: relative;
  top: 0.1em;
  font-size: 0.6em;
  vertical-align: super;
}

.pc-only,
.-break_upper {
  display: block;
}
@media screen and (max-width: 48em) {
  .pc-only,
  .-break_upper {
    display: none;
  }
}

.sp-only,
.-break_lower {
  display: none;
}
@media screen and (max-width: 48em) {
  .sp-only,
  .-break_lower {
    display: block;
  }
}

.-bold {
  font-weight: bold;
}

.-center {
  text-align: center;
}

.-color_primary {
  color: #ec6c00;
}

.-color_secondary {
  color: #032739;
}

.-icon__facebook img {
  width: 100%;
  max-width: 11px;
}
.-icon__mail img {
  width: 100%;
  max-width: 20px;
}
.-icon__instagram img {
  width: 100%;
  max-width: 20px;
}
.-icon__line img {
  width: 100%;
  max-width: 26px;
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.-fade_in {
  opacity: 0;
  transform: translateY(10%);
  transition: 2s;
}
.-fade_in.is-show {
  opacity: 1;
  transform: translateY(0);
}

.-fade_in--left {
  opacity: 0;
  transform: translateX(10%);
  transition: 2s;
}
.-fade_in--left.is-show {
  opacity: 1;
  transform: translateX(0);
}

/* --------------------------------------------------
// layout - header
-------------------------------------------------- */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--body_padding_top-s);
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f7f7f8;
  transition: all 0.3s 0s ease;
}
@media screen and (min-width: 68.76em) {
  .header {
    min-height: var(--body_padding_top-l);
  }
}
.header.is-scroll {
  min-height: var(--body_padding_top-s);
}
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  min-height: var(--body_padding_top-s);
  width: 100%;
  padding: 0;
  background-color: #f7f7f8;
  z-index: 9000;
}

@media screen and (min-width: 68.76em) {
  .header.l-wrap {
    padding-right: 0;
  }
}

.header__menu {
  padding: 0;
}
@media screen and (max-width: 68.75em) {
  .header__menu {
    overflow-y: scroll;
    height: 100vh;
    padding: 60px 35px;
  }
}
.header__logo {
  width: 100%;
  max-width: clamp(162px, 17vw, 250px);
}
.header__logo a {
  transition: all 0.3s 0s ease;
}
.header__logo a:link:hover,
.header__logo a:focus,
.header__logo a[href]:hover {
  opacity: 0.6;
}
@media screen and (max-width: 68.75em) {
  .header__logo {
    max-width: 162px;
  }
}
.header__logo a {
  display: block;
}
@media screen and (min-width: 68.76em) {
  .header__logo a {
    height: 26px;
  }
}
.header .gnav__list {
  display: flex;
}
@media screen and (max-width: 68.75em) {
  .header .gnav__list {
    flex-direction: column;
    border-top: solid 1px rgba(255, 255, 255, 0.3);
  }
}
.header .gnav__list > .-list__item {
  position: relative;
}
@media screen and (min-width: 68.76em) {
  .header .gnav__list > .-list__item:not(:last-of-type) {
    margin-right: 5px;
  }
  .header .gnav__list > .-list__item.is-active .gnav__list--link::before {
    height: 7px;
  }
  .header .gnav__list > .-list__item.is-active .gnav__list--children {
    opacity: 1;
    pointer-events: auto;
  }
}
@media screen and (max-width: 68.75em) {
  .header .gnav__list > .-list__item {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }
}
.header .gnav__list--link {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
}
@media screen and (min-width: 68.76em) {
  .header .gnav__list--link {
    height: 47px;
    font-size: 1.4rem;
    color: #032739;
  }
  .header .gnav__list--link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #032739;
    transition: all 0.3s 0s ease;
  }
}
@media screen and (max-width: 68.75em) {
  .header .gnav__list--link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.6rem;
    font-weight: 600;
    color: #fff;
  }
}
.header .gnav__list--link > .-title {
  display: block;
}
.header .gnav__list--children {
  opacity: 0;
}
@media screen and (min-width: 68.76em) {
  .header .gnav__list--children {
    opacity: 0;
    position: absolute;
    top: 47px;
    left: 0;
    width: 320px;
    padding: 25px 25px 25px 20px;
    background-color: #032739;
    pointer-events: none;
    transition: all 0.3s 0s ease;
  }
}
@media screen and (max-width: 68.75em) {
  .header .gnav__list--children {
    opacity: 1;
    display: none;
    margin-bottom: 15px;
  }
}
.header .gnav__list--children .-list__item {
  position: relative;
}
.header .gnav__list--children .-list__item::before {
  content: "";
  position: absolute;
  top: 0.75em;
  left: 10px;
  width: 5px;
  height: 1px;
  background-color: #fff;
}
.header .gnav__list--children .-list__item:not(:last-of-type) {
  margin-bottom: 5px;
}
.header .gnav__list--sub_link {
  display: block;
  padding-left: 30px;
  font-size: 1.3rem;
  font-weight: 100;
  color: #fff;
}
@media screen and (min-width: 68.76em) {
  .header .gnav__list--sub_link {
    transition: all 0.3s 0s ease;
  }
  .header .gnav__list--sub_link:hover, .header .gnav__list--sub_link:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
@media screen and (max-width: 68.75em) {
  .header .gnav__list--sub_link {
    font-size: 1.5rem;
  }
}
.header__links {
  display: none;
}
@media screen and (max-width: 68.75em) {
  .header__links {
    display: block;
    margin-top: 20px;
  }
}
.header__links .c-sns_links {
  justify-content: center;
}
.header .js-header__menu {
  background-color: transparent;
}
@media screen and (max-width: 68.75em) {
  .header .js-header__menu {
    position: absolute;
    top: 0;
    left: 200%;
    width: 100%;
    background-color: rgba(3, 39, 57, 0.95);
    transition: left 0.8s ease;
  }
  .header .js-header__menu.is-open {
    left: 0;
  }
}

@media screen and (max-width: 68.75em) {
  .js-scroll_fix.is-fixed {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.js-header__menu_icon {
  position: absolute;
  top: 17px;
  right: 20px;
  display: none;
  width: 23px;
  height: 23px;
  background-color: transparent;
  z-index: 999999;
}
@media screen and (max-width: 68.75em) {
  .js-header__menu_icon {
    display: block;
    cursor: pointer;
  }
}
.js-header__menu_icon__wrap {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}
.js-header__menu_icon span {
  display: block;
  width: 30px;
  height: 1px;
  background-color: #032739;
  cursor: pointer;
  transition: all 0.3s 0s ease;
}
.js-header__menu_icon span:not(:last-of-type) {
  margin-bottom: 8px;
}
.js-header__menu_icon.is-active .-first {
  margin-top: 10px;
  background-color: #fff;
  transform: rotate(45deg);
}
.js-header__menu_icon.is-active .-second {
  position: relative;
  margin-top: -9px;
  background-color: #fff;
  transform: rotate(135deg);
}
.js-header__menu_icon.is-active .-third {
  display: none;
}

.js-sp_menu--accordion {
  position: relative;
}
@media screen and (max-width: 68.75em) {
  .js-sp_menu--accordion::after {
    content: "";
    position: absolute;
    top: 45%;
    right: 5px;
    width: 11px;
    height: 11px;
    border-top: solid 1px #fff;
    border-right: solid 1px #fff;
    transform: translateY(-50%) rotate(135deg);
    transition: all 0.3s 0s ease;
  }
  .js-sp_menu--accordion.is-open::after {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}
.js-sp_menu--accordion__btn {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  height: 100%;
  z-index: 9000;
  cursor: pointer;
}
@media screen and (min-width: 68.76em) {
  .js-sp_menu--accordion__btn {
    display: none;
  }
}

/* --------------------------------------------------
// layout - main
-------------------------------------------------- */
.l-wrap {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.l-wrap__inner {
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

/* --------------------------------------------------
// layout - footer
-------------------------------------------------- */
.l-footer {
  margin-top: 60px;
  background-color: #f7f7f8;
}

.l-footer__ground {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: #f7f7f8;
  text-align: center;
}
.l-footer__ground a {
  transition: all 0.3s 0s ease;
}
.l-footer__ground a:link:hover,
.l-footer__ground a:focus,
.l-footer__ground a[href]:hover {
  opacity: 0.6;
}
@media screen and (max-width: 48em) {
  .l-footer__ground {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.l-footer__lower {
  background-color: #032739;
}
.l-footer__lower .l-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
}
@media screen and (max-width: 48em) {
  .l-footer__lower .l-wrap {
    flex-direction: column-reverse;
    justify-content: center;
    min-height: 102px;
  }
}

.footer__head {
  margin-bottom: 40px;
}
.footer__head--title {
  position: relative;
  display: inline-block;
  min-width: 500px;
  min-height: 60px;
  margin-bottom: 30px;
  padding: 15px 20px;
  background-color: #032739;
  border-radius: 100px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
  color: #fff;
}
@media screen and (max-width: 48em) {
  .footer__head--title {
    min-width: 80%;
    border-radius: 15px;
  }
}
.footer__head--title::after {
  content: "";
  position: absolute;
  right: 50px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #032739 transparent transparent transparent;
}
.footer__head--text {
  font-size: 1.5rem;
  font-weight: 100;
}
.footer__logo {
  max-width: 257px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
}
.footer__contact--phone {
  margin-bottom: 25px;
}
.footer__contact--phone .-phone__link {
  display: inline-block;
  margin-bottom: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  line-height: 1;
  color: #ec6c00;
}
.footer__contact--phone .-phone__link .-meta {
  font-size: 2.8rem;
}
.footer__contact--phone .-phone__link .-number {
  font-size: 3.8rem;
}
.footer__contact--phone .-phone__text {
  font-size: 1.3rem;
  font-weight: 100;
}
.footer__contact--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #032739;
  border-radius: 100px;
  font-size: 1.5rem;
  font-weight: 100;
  color: #fff;
}
@media screen and (max-width: 35em) {
  .footer__contact--btn {
    font-size: 1.3rem;
  }
}
.footer__contact--btn .-btn__icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
@media screen and (max-width: 35em) {
  .footer__contact--btn .-btn__icon {
    margin-right: 10px;
  }
}
.footer__contact--btn .-icon__mail {
  display: block;
  max-width: 15px;
  margin-right: 3px;
}
.footer__contact--btn .-icon__line {
  display: block;
  max-width: 17px;
}
.footer__copyright {
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  color: #fff;
}
@media screen and (max-width: 35em) {
  .footer__copyright {
    font-size: 1rem;
  }
}

/* --------------------------------------------------
// object/component - button
-------------------------------------------------- */
.c-btn {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 60px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 20px;
  background-color: #fff;
  border: solid 3px #626b70;
  font-size: 1.5rem;
  font-size: clamp(1.3rem, 3vw, 1.5rem);
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  color: #626b70;
  transition: all 0.3s 0s ease;
}
@media screen and (max-width: 48em) {
  .c-btn {
    width: 70%;
    min-height: 45px;
    border-width: 1px;
  }
}
.c-btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 200%;
  width: 100%;
  height: 100%;
  background-color: #032739;
  transition: all 0.3s 0s ease;
  z-index: 1;
}
.c-btn:hover, .c-btn:focus {
  border-color: #032739;
  color: #fff;
  cursor: pointer;
}
.c-btn:hover::before, .c-btn:focus::before {
  right: 0;
}
.c-btn .form__btn {
  position: relative;
  z-index: 10;
}
.c-btn .-title {
  position: relative;
  z-index: 10;
}
.c-btn--back {
  margin-top: 60px;
}
.c-btn--back .-title {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.c-btn--back .-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 25px;
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateY(-50%);
}
.c-btn--back .-title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  width: 19px;
  height: 19px;
  border-bottom: solid 1px var(--bgb-color_secondary--light);
  border-left: solid 1px var(--bgb-color_secondary--light);
  transform: translateY(-50%) rotate(45deg);
}
.c-btn--back:where(:hover, :focus) .-title::before {
  background-color: var(--bgb-color_white);
}
.c-btn--back:where(:hover, :focus) .-title::after {
  border-color: var(--bgb-color_white);
}

/* --------------------------------------------------
// object/component - category
-------------------------------------------------- */
.c-category {
  display: inline-block;
  min-width: 120px;
  min-height: 26px;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.6rem;
  text-align: center;
  letter-spacing: 0.08em;
  color: #fff;
}
.c-category--01 {
  background-color: #2285b6;
}
.c-category--02 {
  background-color: #59be92;
}
.c-category--03 {
  background-color: #d252b5;
}
.c-category--04 {
  background-color: #e87442;
}
.c-category--05 {
  background-color: #6a55c7;
}

/* --------------------------------------------------
// object/component - sns links
-------------------------------------------------- */
.c-sns_links {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 48em) {
  .c-sns_links {
    margin-bottom: 20px;
  }
}
.c-sns_links .-list__item:not(:last-of-type) {
  margin-right: 40px;
}

/* --------------------------------------------------
// object/component - page_heading
-------------------------------------------------- */
.c-page_heading {
  position: relative;
  width: 100%;
  min-height: 190px;
  margin-bottom: 30px;
}
@media screen and (max-width: 48em) {
  .c-page_heading {
    min-height: 150px;
  }
}
.c-page_heading img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  min-height: 190px;
  object-fit: cover;
  font-size: 0;
  transform: translateY(-50%) translateX(-50%);
}
@media screen and (max-width: 48em) {
  .c-page_heading img {
    min-height: 150px;
  }
}
.c-page_heading__inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 190px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 48em) {
  .c-page_heading__inner {
    min-height: 150px;
  }
}
.c-page_heading .-en {
  margin-bottom: 5px;
  font-size: clamp(1.2rem, 1.5vw, 1.3rem);
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #c7a956;
}
.c-page_heading .-ja {
  font-size: clamp(2.5rem, 5vw, 4.6rem);
  font-weight: 100;
  line-height: 1.3;
  letter-spacing: 0.1em;
  color: #032739;
}

/* --------------------------------------------------
// object/component - article_cassette
-------------------------------------------------- */
@keyframes c-article_cassette__tab_on {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.c-article_cassette #tab__switch--05:checked ~ .c-article_cassette--wide #tab__content--05, .c-article_cassette #tab__switch--05:checked ~ .c-article_cassette--card #tab__content--05, .c-article_cassette #tab__switch--04:checked ~ .c-article_cassette--wide #tab__content--04, .c-article_cassette #tab__switch--04:checked ~ .c-article_cassette--card #tab__content--04, .c-article_cassette #tab__switch--03:checked ~ .c-article_cassette--wide #tab__content--03, .c-article_cassette #tab__switch--03:checked ~ .c-article_cassette--card #tab__content--03, .c-article_cassette #tab__switch--02:checked ~ .c-article_cassette--wide #tab__content--02, .c-article_cassette #tab__switch--02:checked ~ .c-article_cassette--card #tab__content--02, .c-article_cassette #tab__switch--01:checked ~ .c-article_cassette--wide #tab__content--01, .c-article_cassette #tab__switch--01:checked ~ .c-article_cassette--card #tab__content--01, .c-article_cassette #tab__switch--00:checked ~ .c-article_cassette--wide #tab__content--00, .c-article_cassette #tab__switch--00:checked ~ .c-article_cassette--card #tab__content--00 {
  animation-name: c-article_cassette__tab_on;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.c-article_cassette .article_cassette--wide.-new::before, .c-article_cassette .article_cassette--card.-new::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #db3908;
  border-radius: 16px;
  z-index: 15;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide.-new::before, .c-article_cassette .article_cassette--card.-new::before {
    top: 0;
    left: 0;
  }
}

.c-article_cassette {
  width: 100%;
  /* カテゴリ数 5 → 00から05まで */
}
.c-article_cassette .-tab__switch {
  display: none;
}
.c-article_cassette--switch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.c-article_cassette--switch .is-active a {
  background-color: #032739;
  color: #fff;
}
@media screen and (max-width: 48em) {
  .c-article_cassette--switch .-switch__list {
    min-width: calc((100% - 60px) / 3);
  }
}
.c-article_cassette .-tab__list {
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  padding: 6px 10px 8px;
  background-color: transparent;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: #222;
  cursor: pointer;
}
.c-article_cassette .-tab__content {
  display: none;
}
.p-column .-tab__content {
  display: block;
}

.c-article_cassette #tab__switch--00:checked ~ .c-article_cassette--switch #tab__list--00 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--00:checked ~ .c-article_cassette--card #tab__content--00 {
  display: grid;
}
.c-article_cassette #tab__switch--00:checked ~ .c-article_cassette--wide #tab__content--00 {
  display: block;
}
.c-article_cassette #tab__switch--01:checked ~ .c-article_cassette--switch #tab__list--01 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--01:checked ~ .c-article_cassette--card #tab__content--01 {
  display: grid;
}
.c-article_cassette #tab__switch--01:checked ~ .c-article_cassette--wide #tab__content--01 {
  display: block;
}
.c-article_cassette #tab__switch--02:checked ~ .c-article_cassette--switch #tab__list--02 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--02:checked ~ .c-article_cassette--card #tab__content--02 {
  display: grid;
}
.c-article_cassette #tab__switch--02:checked ~ .c-article_cassette--wide #tab__content--02 {
  display: block;
}
.c-article_cassette #tab__switch--03:checked ~ .c-article_cassette--switch #tab__list--03 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--03:checked ~ .c-article_cassette--card #tab__content--03 {
  display: grid;
}
.c-article_cassette #tab__switch--03:checked ~ .c-article_cassette--wide #tab__content--03 {
  display: block;
}
.c-article_cassette #tab__switch--04:checked ~ .c-article_cassette--switch #tab__list--04 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--04:checked ~ .c-article_cassette--card #tab__content--04 {
  display: grid;
}
.c-article_cassette #tab__switch--04:checked ~ .c-article_cassette--wide #tab__content--04 {
  display: block;
}
.c-article_cassette #tab__switch--05:checked ~ .c-article_cassette--switch #tab__list--05 {
  background-color: #032739;
  color: #fff;
}
.c-article_cassette #tab__switch--05:checked ~ .c-article_cassette--card #tab__content--05 {
  display: grid;
}
.c-article_cassette #tab__switch--05:checked ~ .c-article_cassette--wide #tab__content--05 {
  display: block;
}
.c-article_cassette .-tab__content {
  row-gap: 20px;
}
@media screen and (min-width: 48.01em) {
  .c-article_cassette .-tab__content {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
}
@media screen and (max-width: 48em) {
  .c-article_cassette .-tab__content {
    grid-template-columns: 1fr;
  }
}
.c-article_cassette .article_cassette--card {
  position: relative;
  padding: 10px;
  background-color: #fff;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--card {
    padding: 5px;
  }
}
.c-article_cassette .article_cassette--card a,
.c-article_cassette .article_cassette--card > div {
  position: relative;
  display: block;
  height: 100%;
  color: #032739;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--card a,
  .c-article_cassette .article_cassette--card > div {
    display: flex;
  }
}
.c-article_cassette .article_cassette--card a:is(:hover, :focus) .-title {
  text-decoration: underline;
  color: #3e90b2;
}
.c-article_cassette .article_cassette--card a:is(:hover, :focus) .article_cassette--card__thumbnail img {
  transform: scale(1.1);
}
.c-article_cassette .article_cassette--card .c-category {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.c-article_cassette .article_cassette--card__thumbnail {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 200px;
  z-index: 1;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--card__thumbnail {
    width: 140px;
    height: 100px;
  }
}
.c-article_cassette .article_cassette--card__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s 0s ease;
}
.c-article_cassette .article_cassette--card__detail {
  padding: 10px 20px;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--card__detail {
    width: calc(100% - 140px);
    padding: 0 10px;
  }
}
.c-article_cassette .article_cassette--card__detail .-date {
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
  font-size: clamp(1rem, 1vw, 1.3rem);
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.1em;
}
.c-article_cassette .article_cassette--card__detail .-title {
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 500;
  letter-spacing: 0.1em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--card__detail .-title {
    line-height: 1.4;
  }
}
.c-article_cassette .article_cassette--wide {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #dcdedf;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.c-article_cassette .article_cassette--wide.-new::before {
  top: 25px;
  left: 5px;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide.-new::before {
    top: 10px;
    left: 0;
  }
}
.c-article_cassette .article_cassette--wide a,
.c-article_cassette .article_cassette--wide > div {
  position: relative;
  display: block;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  color: #032739;
}
.c-article_cassette .article_cassette--wide a:where(:hover, :focus) {
  background-color: rgba(62, 144, 178, 0.05);
}
.c-article_cassette .article_cassette--wide > div .article_cassette--wide__detail {
  padding-bottom: 0;
}
.c-article_cassette .article_cassette--wide > div .article_cassette--wide__detail::after {
  content: "";
  font-size: 0;
}
.c-article_cassette .article_cassette--wide__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide__head {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.c-article_cassette .article_cassette--wide__head .-date {
  min-width: 90px;
  margin-top: 7px;
  font-family: "Inter", sans-serif;
  font-size: clamp(1.2rem, 1vw, 1.3rem);
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 48.01em) {
  .c-article_cassette .article_cassette--wide__head .-date {
    order: 1;
    margin-right: 10px;
    text-align: left;
  }
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide__head .-date {
    order: 3;
    text-align: right;
  }
}
.c-article_cassette .article_cassette--wide__head .c-category {
  order: 2;
  position: relative;
  min-width: 120px;
  z-index: 5;
}
.c-article_cassette .article_cassette--wide__head .-title {
  display: block;
  width: 100%;
  font-size: clamp(1.8rem, 2.5vw, 2.4rem);
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 48.01em) {
  .c-article_cassette .article_cassette--wide__head .-title {
    order: 3;
    width: calc(100% - 220px);
    margin-top: -0.3em;
    margin-left: 20px;
  }
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide__head .-title {
    order: 1;
    margin-bottom: 15px;
    line-height: 1.4;
  }
}
.c-article_cassette .article_cassette--wide__detail {
  position: relative;
  width: 100%;
  padding-bottom: 2.5em;
}
.c-article_cassette .article_cassette--wide__detail::after {
  content: "全て読む";
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-decoration: underline;
}
.c-article_cassette .article_cassette--wide__detail .-text {
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 400;
  line-height: 1.86;
  letter-spacing: 0.08em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
@media screen and (max-width: 48em) {
  .c-article_cassette .article_cassette--wide__detail .-text {
    line-height: 1.4;
  }
}

/* --------------------------------------------------
// object/component - pagination
-------------------------------------------------- */
.c-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.c-pagination :where(.c-pagination__prev, .c-pagination__next) a {
  position: relative;
  display: block;
  width: 35px;
  height: 35px;
  background-color: transparent;
}
.c-pagination :where(.c-pagination__prev, .c-pagination__next) a::before {
  content: "";
  position: absolute;
  top: 50%;
  width: clamp(10px, 1.5vw, 19px);
  height: clamp(10px, 1.5vw, 19px);
  border-top: solid 1px #032739;
  border-right: solid 1px #032739;
}
.c-pagination :where(.c-pagination__prev, .c-pagination__next) a:where(:hover, :focus) {
  background-color: rgba(62, 144, 178, 0.05);
}
.c-pagination__prev a::before {
  left: 65%;
  transform: translateY(-50%) translateX(-50%) rotate(-135deg);
}
.c-pagination__next a::before {
  left: 35%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
.c-pagination span:not(.-prev, .-next) {
  background-color: #000;
}
.c-pagination__number {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.c-pagination__number .-list__item {
  margin-right: 3px;
  margin-left: 3px;
}
.c-pagination__number a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  font-weight: 200;
  color: #032739;
}
.c-pagination__number a:where(:hover, :focus) {
  background-color: rgba(62, 144, 178, 0.05);
}
.c-pagination .-current a {
  background-color: #032739;
  color: #fff;
  pointer-events: none;
}

/* --------------------------------------------------
// object/component - tag
-------------------------------------------------- */
.c-tag__nav {
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 40px;
  border-top: solid 1px rgba(3, 39, 57, 0.2);
  border-bottom: solid 1px rgba(3, 39, 57, 0.2);
}
@media screen and (max-width: 48em) {
  .c-tag__nav {
    padding: 30px 5px 20px;
  }
}
.c-tag__nav::before {
  content: "TAG";
  position: absolute;
  top: 5px;
  left: 5px;
  padding-right: 16px;
  background: url(../img/icon_tag.svg) no-repeat right center;
  background-size: 14px 14px;
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.15em;
  color: #cdd4d7;
}
.c-tag__nav--item {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 48em) {
  .c-tag__nav--item {
    display: block;
  }
}
.c-tag__nav--item:not(:last-child) {
  margin-bottom: 10px;
}
.c-tag__nav--item .-tag__item--title {
  position: relative;
  display: inline-block;
  width: 170px;
  margin-right: 10px;
  padding-right: 10px;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: right;
  text-transform: capitalize;
  color: #032739;
}
@media screen and (max-width: 48em) {
  .c-tag__nav--item .-tag__item--title {
    width: auto;
    text-align: left;
  }
}
.c-tag__nav--item .-tag__item--title::after {
  content: "";
  position: absolute;
  top: 54%;
  right: 0;
  width: 5px;
  height: 1px;
  background-color: #032739;
}
.c-tag__nav--item .-tag__item--list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% - 180px);
}
@media screen and (max-width: 48em) {
  .c-tag__nav--item .-tag__item--list {
    width: 100%;
  }
}
.c-tag__nav--item .-tag__item--list .-list__item {
  margin-bottom: 5px;
}
.c-tag__nav--item .-tag__item--list .-list__item:not(:last-child) {
  margin-right: 10px;
}
.c-tag__nav--item .-tag__item--list .-list__item.is-active a {
  background-color: #032739;
  color: #fff;
}
.c-tag__nav--item .-tag__item--list a {
  display: inline-block;
  padding: 0px 12px;
  background-color: #f7f7f8;
  border-radius: 20px;
  font-size: 1.3rem;
  color: #032739;
}
.c-tag__nav--item .-tag__item--list a:hover {
  background-color: #032739;
  color: #fff;
}
.c-tag__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-left: 20px;
  background: url(../img/icon_tag.svg) no-repeat left center;
  background-size: 14px 14px;
}
.c-tag__list .-list__item {
  margin-bottom: 5px;
}
.c-tag__list .-list__item:not(:last-child) {
  margin-right: 10px;
}
.c-tag__list .-list__item.is-active a {
  background-color: #032739;
  color: #fff;
}
.c-tag__list a {
  display: inline-block;
  padding: 0px 12px;
  background-color: #f7f7f8;
  border-radius: 20px;
  font-size: 1.3rem;
  color: #032739;
}
.c-tag__list a:hover {
  background-color: #032739;
  color: #fff;
}

/* --------------------------------------------------
// object/project - top_page
-------------------------------------------------- */
.p-top {
  margin-bottom: -60px;
}
.p-top .p-top__section {
  padding-top: 70px;
  padding-bottom: 70px;
}
.p-top .p-top__section--title {
  margin-bottom: 40px;
  text-align: center;
}
.p-top .p-top__section--title .-en {
  display: block;
  font-size: clamp(3.5rem, 4vw, 5rem);
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #032739;
}
.p-top .p-top__section--title .-ja {
  display: block;
  margin-top: 15px;
  font-size: 1.4rem;
  font-size: clamp(1.2rem, 1.5vw, 1.4rem);
  font-weight: 100;
  line-height: 1;
  color: #626b70;
}
.p-top__fv {
  position: relative;
  width: 100%;
  height: 480px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 48em) {
  .p-top__fv {
    height: 250px;
  }
}
.p-top__fv .p-top__fv--bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.p-top__fv .-bg__img {
  overflow-x: hidden;
  height: 480px;
}
@media screen and (max-width: 48em) {
  .p-top__fv .-bg__img {
    height: 250px;
  }
}
.p-top__fv .-bg__img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/1;
}
.p-top__fv .l-wrap {
  position: relative;
  height: 100%;
  z-index: 10;
}
.p-top__fv--text {
  position: absolute;
  padding: 0.4em 20px 0.55em;
  background-color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 2.8rem;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #032739;
}
@media screen and (max-width: 48em) {
  .p-top__fv--text {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 1.4rem;
  }
}
.p-top__fv--text.-first {
  bottom: 22%;
  right: 30%;
}
@media screen and (max-width: 35em) {
  .p-top__fv--text.-first {
    right: 38%;
  }
}
@media screen and (min-width: 68.76em) {
  .p-top__fv--text.-first {
    bottom: 25%;
    right: 25%;
  }
}
.p-top__fv--text.-second {
  bottom: 7%;
  right: 15px;
}
@media screen and (min-width: 68.76em) {
  .p-top__fv--text.-second {
    bottom: 10%;
  }
}
@media screen and (max-width: 48em) {
  .p-top__works > .l-wrap {
    padding-right: 0;
    padding-left: 0;
  }
}
.p-top__works .works__slider {
  padding-top: 30px;
}
@media screen and (min-width: 48.01em) {
  .p-top__works .works__slider {
    margin-bottom: 20px;
  }
}
.p-top__works .works__slider--list {
  position: relative;
}
.p-top__works .works__slider--list__thmbnail {
  width: min(100%, 1000px);
  height: auto;
  max-height: 460px;
  margin-right: auto;
  margin-left: auto;
  aspect-ratio: 2/1;
}
.p-top__works .works__slider--list__thmbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.p-top__works .works__slider--list__detail {
  width: min(100%, 600px);
  padding: 20px;
  text-align: center;
}
@media screen and (min-width: 48.01em) {
  .p-top__works .works__slider--list__detail {
    position: absolute;
    bottom: 20px;
    left: 50%;
    min-width: 600px;
    background-color: rgba(3, 39, 57, 0.7);
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider--list__detail {
    position: static;
  }
}
.p-top__works .works__slider--list__detail .c-category {
  min-width: 150px;
  margin-bottom: 10px;
}
@media screen and (min-width: 48.01em) {
  .p-top__works .works__slider--list__detail .c-category {
    position: absolute;
    top: -15px;
    left: -15px;
  }
}
.p-top__works .works__slider--list__detail .-detail__title {
  font-size: 2.4rem;
  font-size: clamp(2.1rem, 3vw, 2.4rem);
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #fff;
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider--list__detail .-detail__title {
    font-weight: 500;
    color: #032739;
  }
}
.p-top__works .works__slider--list__detail .-detail__nameplate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  color: #fff;
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider--list__detail .-detail__nameplate {
    margin-top: 10px;
    color: #032739;
  }
}
.p-top__works .works__slider--list__detail .-name {
  font-size: 1.3rem;
  font-size: clamp(1.2rem, 1.5vw, 1.3rem);
  font-weight: 100;
}
.p-top__works .works__slider--list__detail .-name::after {
  content: "/";
  padding-left: 10px;
  color: #fff;
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider--list__detail .-name::after {
    color: #032739;
  }
}
.p-top__works .works__slider--list__detail .-date {
  padding-left: 10px;
  font-family: "Inter", sans-serif;
  font-size: clamp(1.2rem, 1.5vw, 1.3rem);
  font-weight: 100;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider .slick-slider {
    margin-bottom: 0;
  }
}
.p-top__works .works__slider .slick-arrow {
  top: 50%;
  width: clamp(22px, 4vw, 44px);
  height: clamp(22px, 4vw, 44px);
  background-color: rgba(98, 107, 112, 0.8);
  border-radius: 100px;
  z-index: 100;
}
@media screen and (max-width: 48em) {
  .p-top__works .works__slider .slick-arrow {
    top: 25%;
  }
}
.p-top__works .works__slider .slick-arrow::before {
  font-size: 0;
  content: "";
  position: absolute;
  top: 50%;
  width: clamp(7px, 1vw, 14px);
  height: clamp(7px, 1vw, 14px);
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
}
.p-top__works .works__slider .slick-prev {
  left: 10px;
}
@media screen and (min-width: 68.76em) {
  .p-top__works .works__slider .slick-prev {
    left: 0;
  }
}
.p-top__works .works__slider .slick-prev::before {
  left: 57%;
  transform: translateY(-50%) translateX(-50%) rotate(-135deg);
}
.p-top__works .works__slider .slick-next {
  right: 10px;
}
@media screen and (min-width: 68.76em) {
  .p-top__works .works__slider .slick-next {
    right: 0;
  }
}
.p-top__works .works__slider .slick-next::before {
  left: 45%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
.p-top__works .works__slider .slick-dots {
  top: -45px;
  bottom: unset;
}
.p-top__works .works__slider .slick-dots li {
  width: 10px;
  height: 10px;
}
.p-top__works .works__slider .slick-dots li button {
  width: 10px;
  height: 10px;
  padding: 0;
}
.p-top__works .works__slider .slick-dots li button::before {
  width: 10px;
  height: 10px;
  background-color: #626b70;
  border-radius: 10px;
  font-size: 0;
  line-height: 10px;
}
.p-top__works .works__slider .slick-dots li.slick-active button::before {
  background-color: #032739;
}
.p-top__service {
  background-color: #032739;
}
.p-top__service .p-top__section--title :is(.-en, .-ja) {
  color: #fff;
}
.p-top__service .service__list {
  display: grid;
  margin-bottom: 60px;
}
@media screen and (min-width: 48.01em) {
  .p-top__service .service__list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    row-gap: 40px;
  }
}
@media screen and (max-width: 48em) {
  .p-top__service .service__list {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}
.p-top__service .service__list .-list__item {
  overflow: hidden;
  position: relative;
  background-color: #032739;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 2/1;
  text-align: center;
}
.p-top__service .service__list .-list__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  transition: all 0.3s 0s ease;
  opacity: 1;
  z-index: 1;
}
.p-top__service .service__list .-list__item:where(:hover, :focus)::before {
  opacity: 0.3;
}
.p-top__service .service__list .-list__item a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.p-top__service .service__list .-list__item .-en {
  display: block;
  font-family: "Inter", sans-serif;
  font-size: clamp(2.5rem, 2vw, 3rem);
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.15em;
  color: #c7a956;
}
.p-top__service .service__list .-list__item .-ja {
  display: block;
  margin-top: 15px;
  font-size: clamp(1.3rem, 2vw, 1.6rem);
  line-height: 1;
  letter-spacing: 0.15em;
  color: #fff;
}
.p-top__service .service__list--house {
  background-image: url(../img/top/service_img01.jpg);
}
.p-top__service .service__list--renovation {
  background-image: url(../img/top/service_img02.jpg);
}
.p-top__service .service__list--furniture {
  background-image: url(../img/top/service_img03.jpg);
}
.p-top__service .service__list--shop {
  background-image: url(../img/top/service_img04.jpg);
}
.p-top__service .service__list--reform {
  background-image: url(../img/top/service_img05.jpg);
}
.p-top__service .service__list--other::before {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.p-top__service .c-btn:is(:hover, :focus) {
  border-color: #626b70;
}
.p-top__column {
  background-color: #f7f7f8;
}
.p-top__recruit {
  position: relative;
  background: url(../img/top/recruit_img01.jpg) no-repeat 70% center;
  background-size: cover;
}
@media screen and (min-width: 68.76em) {
  .p-top__recruit {
    min-height: 520px;
  }
}
.p-top__recruit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(198, 233, 250, 0.6);
}
.p-top__recruit .recruit__text {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #032739;
}

/* --------------------------------------------------
// object/project - column_page
-------------------------------------------------- */
.p-column .c-article_cassette {
  margin-bottom: 40px;
}
.p-column .c-article_cassette--switch {
  min-height: 80px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #f7f7f8;
}
@media screen and (max-width: 48em) {
  .p-column .c-article_cassette--switch {
    margin-bottom: 0;
  }
}

.p-column_detail--head {
  display: flex;
  align-items: center;
}
.p-column_detail--head .-date {
  min-width: 90px;
  margin-right: 10px;
  font-family: "Inter", sans-serif;
  font-size: clamp(1.2rem, 1vw, 1.3rem);
  font-weight: 100;
  line-height: 1;
  letter-spacing: 0.1em;
}

/* --------------------------------------------------
// object/project - about_page
-------------------------------------------------- */
.p-about .c-page_main p strong,
.p-about .c-page_main p b {
  position: relative;
  padding-bottom: 4px;
  border-bottom: solid 2px #032739;
  font-weight: 500;
  color: #032739;
}

/* --------------------------------------------------
// object/project - contact_page
-------------------------------------------------- */
.p-contact__phone {
  margin-bottom: 25px;
}
.p-contact__phone .-phone__link {
  display: inline-block;
  margin-bottom: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  line-height: 1;
  color: #ec6c00;
}
.p-contact__phone .-phone__link .-meta {
  font-size: 2.8rem;
}
.p-contact__phone .-phone__link .-number {
  font-size: 3.8rem;
}
.p-contact__phone .-phone__text {
  font-size: 1.3rem;
  font-weight: 100;
}
.p-contact__line {
  text-align: center;
}
@media screen and (max-width: 48em) {
  .p-contact__line {
    text-align: left;
  }
}
.p-contact__line--image {
  max-width: 360px;
  margin-right: auto;
  margin-left: auto;
}
.p-contact__line--text__big {
  margin-bottom: 10px;
  font-size: clamp(2rem, 2.2vw, 2.4rem);
  line-height: 1.4;
}
.p-contact form {
  margin-bottom: 100px;
}
.p-contact__form .alert-message {
  margin-top: 30px;
  padding: 10px 20px;
  border: solid 3px #ec6c00;
  font-weight: bold;
  color: #ec6c00;
}
.p-contact__form .form__input {
  min-width: 500px;
  padding: 0.38em 10px;
  border: solid 1px #d0d2d4;
}
@media screen and (max-width: 48em) {
  .p-contact__form .form__input {
    min-width: auto;
    width: 100%;
  }
}
.p-contact__form .form__area {
  min-width: 500px;
  padding: 0.38em 10px;
  border: solid 1px #d0d2d4;
}
@media screen and (max-width: 48em) {
  .p-contact__form .form__area {
    min-width: auto;
    width: 100%;
  }
}
.p-contact__form .form__check {
  display: none;
}
.p-contact__form .form__check:checked + label::after {
  content: "";
  position: absolute;
  top: 45%;
  left: 10px;
  width: 8px;
  height: 15px;
  border-right: solid 2px #ec6c00;
  border-bottom: solid 2px #ec6c00;
  transform: translateY(-50%) rotate(45deg);
  z-index: 10;
}
.p-contact__form .form__label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}
@media screen and (max-width: 48em) {
  .p-contact__form .form__label {
    margin-bottom: 10px;
  }
}
.p-contact__form .form__label:not(:last-child) {
  margin-right: 30px;
}
.p-contact__form .form__label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 26px;
  height: 26px;
  border: solid 1px #d0d2d4;
  border-radius: 5px;
  transform: translateY(-50%);
}
.p-contact__form .error-message {
  position: relative;
  margin-top: 10px;
  font-size: 80%;
  font-weight: bold;
  color: #ec6c00;
}
.p-contact__form .error-message::before {
  content: "＊";
  margin-right: 3px;
  vertical-align: super;
}
.p-contact__form--table {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
}
@media screen and (max-width: 68.75em) {
  .p-contact__form--table {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 68.75em) {
  .p-contact__form--table tbody {
    display: block;
    width: 100%;
  }
}
.p-contact__form--table tr.-table__item {
  border-bottom: solid 1px #d0d2d4;
}
.p-contact__form--table tr.-table__item:first-child {
  border-top: solid 1px #d0d2d4;
}
@media screen and (max-width: 68.75em) {
  .p-contact__form--table tr.-table__item {
    display: block;
    width: 100%;
  }
}
.p-contact__form--table .-required {
  margin-left: 5px;
  font-size: 10px;
  vertical-align: super;
  color: #ec6c00;
}
.p-contact__form--table th.-table__title {
  padding: 15px 20px;
  background-color: #f7f7f8;
  font-weight: 300;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 68.75em) {
  .p-contact__form--table th.-table__title {
    display: block;
    width: 100%;
  }
}
.p-contact__form--table td.-table__detail {
  padding: 15px 20px;
  font-weight: 100;
}
@media screen and (max-width: 68.75em) {
  .p-contact__form--table td.-table__detail {
    display: block;
    width: 100%;
  }
}
.p-contact__form--table .-table__detail--inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 48em) {
  .p-contact__form--table .-table__detail--inner {
    align-items: flex-start;
    flex-direction: column;
  }
}
.p-contact__privacy {
  margin-bottom: 60px;
  padding: 20px;
  background-color: #f7f7f8;
  text-align: center;
}
.p-contact__privacy .-text {
  letter-spacing: 0.1em;
}
.p-contact__privacy a {
  text-decoration: underline;
}

/* --------------------------------------------------
// object/project - faq_page
-------------------------------------------------- */
.p-faq .p-faq__item:not(:last-child) {
  margin-bottom: 60px;
}
.p-faq .p-faq__item--question {
  margin-left: clamp(45px, 5vw, 65px);
  padding-left: 0;
}
.p-faq .p-faq__item--question::before {
  content: "Q.";
  top: -2rem;
  left: -60px;
  width: auto;
  height: auto;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: clamp(4rem, 5vw, 5rem);
  font-weight: 200;
  color: #032739;
}
@media screen and (max-width: 48em) {
  .p-faq .p-faq__item--question::before {
    left: -45px;
  }
}
.p-faq .p-faq__item--answer {
  position: relative;
  margin-left: clamp(70px, 8vw, 95px);
}
.p-faq .p-faq__item--answer::before {
  content: "A.";
  position: absolute;
  top: -0.35rem;
  left: -30px;
  margin-right: 10px;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: clamp(1.6rem, 3vw, 2rem);
  font-weight: 700;
  color: #ec6c00;
}

/* --------------------------------------------------
// object/project - case_study page
-------------------------------------------------- */
.p-case_study .c-article_cassette--switch {
  min-height: 80px;
  padding: 20px;
  background-color: #f7f7f8;
}
.p-case_study .-tab__content {
  display: grid;
}
.p-case_study .article_cassette--card {
  background-color: #f7f7f8;
}

/* --------------------------------------------------
// object/project - other
-------------------------------------------------- */
.p-other__title {
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-bottom: solid 1px #032739;
  font-size: clamp(2.5rem, 3vw, 3.6rem);
  font-weight: 600;
  line-height: 1.4;
  color: #032739;
}
.p-other__title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 10px;
  background-color: #032739;
}
.p-other__text {
  font-size: clamp(1.4rem, 1.5vw, 1.5rem);
  line-height: 1.8;
  letter-spacing: 0.08em;
  color: #626b70;
}
.p-other__text:not(:first-child) {
  margin-top: var(--bgb-block_mt);
}